.navbarMobile {
  position: fixed;
  top: 0rem;
  right: 0;
  padding: 0rem 1.6rem;
  display: none;
  flex-direction: column;
  list-style: none;
  transition: all 200ms ease-in-out;
  height: 100vh;
  background-color: var(--clr_LightGrey);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  overflow: scroll;
  font-size: 1.6rem;
  font-weight: 500;
  width: 28rem;
  /* behavior //================================================================== */
  opacity: 0;
  pointer-events: none;
  transform: translateX(100%);
  z-index: 99;
}
.showNavbarMobile {
  opacity: 1;
  pointer-events: all;
  transform: translateX(0%);
}
.navbarMobile a {
  text-decoration: none;
  color: var(--font_color_light);
}
.headingAndClose {
  height: 5.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2.4rem;
  gap: 2rem;
  border-bottom: rgba(128, 128, 128, 0.493) double 6px;
  padding: 0.6rem 0.6rem 0 0;
}
.x {
  justify-content: end;
}
.backIcon {
  font-size: 3rem;
}
.divNavElementMobile {
  display: flex;
  justify-content: space-between;
  border-bottom: rgba(128, 128, 128, 0.541) solid 2px;
}
.navElementMobile {
  padding: 1rem 1rem 1rem 0;
  min-width: min-content;
  width: 100%;
}
.divArrow {
  z-index: 9999999;
  font-size: 2rem;
  padding: 0 0.6rem 0 1rem;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.textCrossedOut {
  text-decoration: line-through;
}

@media screen and (max-width: 1150px) {
  .navbarMobile {
    display: flex;
  }
}
@media screen and (max-width: 500px) {
  .navbarMobile {
    font-size: 2rem;
  }
  .headingAndClose {
    font-size: 3rem;
  }
  .divArrow {
    font-size: 2.5rem;
    padding: 0 1rem;
  }
  .backIcon {
    font-size: 3.5rem;
  }
  .divNavElementMobile {
    /* padding: 1.4rem 0; extra line space*/
  }
}
