.imgBoxComponent {
  display: flex;
  justify-content: start;
  overflow-x: auto;
  margin-bottom: 1rem;
  gap: 2rem;
  background-color: rgb(235, 235, 235);
  padding: 1rem;
}

.hover {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}
.imgBox {
  display: flex;
  justify-content: center;
}
.rounded {
  border-radius: var(--border-radius);
}
.multiply {
  mix-blend-mode: multiply;
}

.textBox {
  flex-wrap: wrap;
}
@media screen and (max-width: 700px) {
  .imgBoxComponent {
    flex-direction: column;
  }
}
