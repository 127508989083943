.leafletContainer {
  background-color: rgb(178, 210, 222);
  height: 100%;
  width: 100%;
  /* border: red solid 2px; */
}

.leafletContainer * {
  z-index: 1 !important;
}
.customMarkerCluster {
  background: #e2e2e288;
  background: #e2e2e2bd;
  border: 0.5rem solid #e7534e;
  border-radius: 50%;
  color: #000000;
  text-align: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 700;
  /* 
  ### optional custom img for marker
  background-image: url(../../../public/xlMarker.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; */
}

.cssSmall {
  line-height: 3.2rem;
}
.cssLarge {
  line-height: 5.2rem;
}
