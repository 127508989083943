.linkBox {
  color: var(--font_color_light);
  font-weight: 900;
  display: flex;
  text-decoration: none;
}
.linkBox:hover {
  cursor: pointer;
}
.textBox {
  display: flex;
  justify-content: center;
  padding-top: 1.1rem;
  width: 100%;
}
.text {
  display: flex;
  font-family: 'Indie Flower', Sans-Serif;
  font-size: 2.2rem;
}
.text:hover {
  text-decoration: underline;
}
.diceIcon {
  transform: translateY(0.2rem);
}
.diceIcon:has(+ .text:hover) {
  animation: dice-puls 0.4s infinite alternate;
}
@keyframes dice-puls {
  0% {
    transform: translateY(0.2rem) translateX(0) scale(1);
  }
  100% {
    transform: translateY(0.4rem) translateX(-0.6rem) scale(1.5);
  }
}
@media screen and (max-width: 1150px) {
  .linkBoxImg {
    max-width: 50rem;
    align-self: center;
  }
}
@media screen and (max-width: 500px) {
  .linkBoxImg {
    max-width: 100%;
  }
}
