.map {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.leafletOuterBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.leafletBox {
  width: 800px;
  height: 600px;
  max-width: 86vw;
}
@media screen and (min-width: 1150px) {
  .leafletBox {
    width: 80vw;
    height: 80vh;
    max-width: 90vw;
  }
}
.buttonDiv {
  display: flex;
  gap: 2rem;
}
