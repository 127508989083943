.imgBoxComponent {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  overflow-x: auto;
  margin-bottom: 1rem;
  background-color: rgb(235, 235, 235);
  padding: 1rem;
}
.textBox {
  padding: 0 0.8rem;
}
.textBelowLeft {
  text-align: left;
}
.imgGridBox {
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
}
.rounded {
  border-radius: var(--border-radius);
}
.multiply {
  mix-blend-mode: multiply;
}

.textBox {
  flex-wrap: wrap;
}
@media screen and (max-width: 700px) {
  .imgBoxComponent {
    flex-direction: column;
  }
}
