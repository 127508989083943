.highLightBox {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  margin-bottom: 1rem;
  width: 100%;
}
.highLightTextBox {
  width: 100%;
  text-align: center;
}

.rounded {
  border-radius: var(--border-radius);
}
.multiply {
  mix-blend-mode: multiply;
}
.hover {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  scale: 1.01;
}
