.footerColor {
  position: relative;
  width: 100vw;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  background: var(--linear_gradientOT);
}
.footerColor::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 2rem;
  width: 100%;
  background: var(--linear_gradientTO);
}
.footer {
  color: var(--font_color_footer);
  min-height: 15rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.linkDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}
.link {
  all: unset;
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  .link:hover {
    text-decoration: underline;
  }
}
