.sidebar {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  width: 30%;
  max-width: 30rem;
  padding: 1rem;
  margin-top: 1rem;
  background: var(--linear_gradientSidebar);
  border-radius: var(--border-radius);
}

.sidebar ol {
  transform: translateX(3rem);
}
.sidebar ul {
  transform: translateX(3rem);
  max-width: 90%;
}
.sidebar ul li {
  margin-bottom: 1rem;
}
.sidebar h2 {
  text-align: center;
}
.sidebarLogo {
  align-self: end;
  width: 100%;
  scale: 1;
}
.advertisingBox {
  flex-grow: 1;
  padding: 2rem 0;
}
.flexGrowBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
@media screen and (max-width: 1150px) {
  .sidebar {
    width: 100%;
    max-width: 120rem;
  }
}
