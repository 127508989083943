.page {
  width: 100%;
  height: min-content;
  overflow-y: hidden;
  overflow-x: hidden;
}
.pageContent {
  display: flex;
  justify-content: center;
  padding: 1rem 2rem 2rem 2rem;
  gap: 2rem;
  min-height: 30rem;
  min-height: 80vh;
}

@media screen and (max-width: 1150px) {
  .pageContent {
    flex-direction: column;
    align-items: center;
    padding: 1.4rem;
  }
}
