.linkListBox {
  text-align: center;
  /* border-top: rgb(71, 71, 71) solid 2px;
  border-bottom: rgb(71, 71, 71) solid 2px; */
}
.heading {
  padding: 0.4rem 0 0.3rem 0;
  font-size: 1.9rem;
  font-weight: var(--font-weightSidebarHeading);
  font-family: var(--font-familySidebar), sans-serif;
  margin-bottom: 0;
}
.linkBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flexBoxes {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 400;
  border-bottom: rgb(83, 68, 0) solid 1px;
  padding: 1rem 0;
  gap: 0.6rem;
}
.flexBoxes:last-child {
  border-bottom: none;
}
.listCountIcon {
  display: flex;
  align-items: center;
  line-height: 1.2em;
  justify-content: center;
  height: 100%;
  min-width: 3.6rem;
  filter: drop-shadow(0rem 0rem 0.15rem rgba(0, 0, 0, 1));
}
.aBox {
  display: flex;
  align-items: center;
  text-align: start;
  max-width: 80%;
  cursor: pointer;
}
.a {
  /* font-family: 'roboto', sans-serif; */
  text-decoration: none;
}
/* @media (hover: hover) and (pointer: fine) { */
.a:hover {
  text-decoration: underline;
}
.listCountIcon:has(+ .a:hover) {
  animation: ease-in-out;
  scale: 1.3;
}

@media screen and (max-width: 1150px) {
  .linkBox {
    margin-top: 1.2rem;
    flex-direction: row;
    justify-content: center;
    row-gap: 2rem;
    column-gap: 3rem;
    flex-wrap: wrap;
  }
  .listCountIcon {
    min-width: min-content;
  }
  .flexBoxes {
    border-bottom: none;
    width: max-content;
  }
  .aBox {
    display: block;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: max-content;
    max-width: max-content;
  }
}

@media screen and (max-width: 500px) {
  .linkBox {
    flex-direction: column;
    gap: 2rem;
    margin-top: 1.2rem;
    align-items: center;
    flex-wrap: nowrap;
  }
  .flexBoxes {
    border-bottom: rgb(83, 68, 0) solid 1px;
    width: 100%;
  }
  .aBox {
    display: flex;
    align-items: center;
    text-align: start;
    max-width: 80%;
  }
  .listCountIcon {
    min-width: 3rem;
  }
}
