.dropdown {
  list-style: none;
  padding: 1rem 1.6rem;
}
.dropdownElement {
  list-style: none;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 2.5rem;
  white-space: nowrap;
}
/* @media (hover: hover) and (pointer: fine) { BUG*/
.dropdownElement:hover {
  color: var(--clr_orange);
  cursor: pointer;
}
/* } */
.textCrossedOut {
  text-decoration: line-through;
}
