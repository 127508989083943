.popupBox p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  margin-bottom: 0.4rem;
}
.popupBox {
  /* gap: 2.5rem; */
}
.marker img {
  color: yellow !important;
  border: red solid 2px;
  width: 10rem;
}
.buttonTextBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: 0.5rem;
  padding: 1rem;
  border: var(--clr_grey) solid 2px;
  margin-bottom: 1rem;
}
.buttonBox {
  display: flex;
  justify-content: center;
  gap: 3rem;
  border-radius: 0.5rem;
}

.icon {
  width: 4rem;
}
.icon:hover {
  filter: drop-shadow(2px 2px 6px #00000060);
}
