*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-size: 62%;
}
body {
  font-size: 1.8rem;
  position: relative;
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--clr_background_light);
  color: var(--font_color_light);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  padding: 1rem 0 0 0;
}

h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.8rem;
  padding: 1.6rem 0 0 0;
}
p {
  margin-bottom: 0.8rem;
}
h2 {
  font-size: 2.6rem;
}
h3 {
  font-size: 2.1rem;
}
h4 {
  font-size: 1.8rem;
}
h5 {
  font-size: 1.6rem;
}
h6 {
  font-size: 3.5rem;
  font-family: 'Indie Flower', Sans-Serif;
  text-align: center;
}
a:link {
  color: var(--font_color_light);
}
