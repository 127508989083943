.dateBox {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 1.25rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 300;
  color: rgb(121, 121, 121);
  width: 100%;
  flex-wrap: wrap;
  column-gap: 1rem;
  transform: translateY(0.5rem);
}
.hide {
  position: absolute;
  transform: translateY(0);
  top: 0;
  color: rgba(0, 0, 0, 0);
}
.pBox {
  display: flex;
  justify-content: start;
  gap: 0.5rem;
}
.dateBox p {
  margin: 0 !important;
}
.transparent {
  color: rgba(0, 0, 0, 0);
}
.preview {
  position: absolute;
  margin-left: 1rem;
  width: 97%;
  transform: translateY(-1rem);
}
