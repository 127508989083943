.box {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}
.textHBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 1rem 2rem 1rem 2rem;
  border: #cac7bc solid 2px;
  background-color: var(--clr_tagBackground);
  border-radius: 1.5rem;
  max-width: 80rem;
  gap: 1rem;
  font-family: var(--ff_indie);
}
.textHBox h3 {
  line-height: 100%;
  margin: 0;
  padding: 0;
}
.CountdownHeader {
  font-size: 3rem;
  letter-spacing: 0.5rem;
}

.days {
  font-size: 4rem;
}

@media screen and (max-width: 600px) {
  .CountdownHeader {
    font-size: 2.5rem;
    letter-spacing: 0rem;
  }
  .days {
    font-size: 3.4rem;
  }
}
