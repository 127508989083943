.leafletContainer {
  background-color: rgb(178, 210, 222);
  height: 100%;
  width: 100%;
  /* border: red solid 2px; */
}

.leafletContainer * {
  z-index: 1 !important;
}
.customMarkerCluster {
  background: #e2e2e288;
  background: #e2e2e2bd;
  border: 0.5rem solid #e7534e;
  border-radius: 50%;
  color: #000000;
  text-align: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 700;
  /* 
  ### optional custom img for marker
  background-image: url(../../../public/xlMarker.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; */
}

.cssSmall {
  line-height: 3.2rem;
}
.cssLarge {
  line-height: 5.2rem;
}
/* .leaflet-control-attribution.leaflet-control {
  display: none;
} */

.box {
  width: 100%;
  height: 100%;
  display: flex;
}
.loader {
  position: absolute;
  top: 10%;
  left: 48%;
  width: 5rem;
  height: 5rem;
  z-index: 2;
  border: 6px solid #ffffff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: flex;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loaderKill {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  font-size: 2rem;
  text-align: center;
  top: 20%;
  left: 0%;
  width: 60%;
  transform: translateX(33%);
  border: var(--clr_orange) solid 2px;
  z-index: 2;
  border-radius: 1rem;
  padding: 2rem;
  color: aliceblue;
  background-color: #000000a1;
}
