.divIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
}
.divLink {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  border-radius: 50%;
}
.link {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}
.icon {
  width: 3rem;
}
.divLink:hover .hoverClass {
  scale: 1.1;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}
.divLink:active {
  scale: 1.04;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}
.blurredBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  filter: blur(3px);
  z-index: -1;
}

.divLink:hover .fb {
  background-color: #4e78ce;
}
.divLink:hover .yt {
  background-color: #ee0e0f;
}
.divLink:hover .tt {
  background-color: #ee1d52;
}
