.cookiesBoxPosition {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.cookiesBox {
  z-index: 999999;
  background-color: var(--clr_background_light);
  border-radius: var(--border-radius);
  padding: 1rem 2rem;
  width: 40%;
  max-height: 90vh;
  min-width: 34rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  flex-direction: column;
  font-size: 1.3rem;
}
@media screen and (max-width: 1150px) {
  .cookiesBox {
    width: 60%;
  }
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  font-size: 1.8rem;
  gap: 1rem;
}
.cookieSettingsGridBox {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr max-content 1fr min-content;
  width: 100%;
  padding: 0.5rem;
}
.gridCell {
  padding: 0.2rem 0;
}
.gridCellLabel {
  grid-column-start: 2;
  grid-column-end: 3;
  padding: 0.2rem 1rem;
  text-decoration: underline;
  cursor: pointer;
}
.gridCellInput {
  display: flex;
  padding: 0 1rem;
}
.gridCellX {
  padding: 0 1rem;
  cursor: pointer;
}

.cookieContent {
  grid-column-start: 1;
  grid-column-end: 5;
  margin-bottom: 2rem;
  /* padding: 0.5rem 1rem; */
  width: 100%;
}
.cookieContentActive {
  background-color: antiquewhite;
}
.cookieContentTableBox {
  border-bottom: grey double 4px;
  max-height: 15rem;
  overflow-y: scroll;
}
.cookieContentTableHead {
  padding: 1rem;
  display: flex;
  justify-content: center;
  background-color: rgb(235, 210, 177);
  border-top: rgb(177, 177, 177) double 6px;
}
.cookieContentTable {
  /* display: flex; */
  gap: 1rem;
  padding: 0.5rem 1rem;
  border-top: grey solid 2px;
  overflow-wrap: anywhere;
}

.buttonBox {
  display: flex;
  gap: 2rem;
  margin-bottom: 1.6rem;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  background: none;
  border-radius: 0.8rem;
  text-decoration: none;
  color: rgb(36, 36, 36);
  border: none;
}
.buttonConfirm {
  font-size: 1.7rem;
  background-color: var(--clr_orange);
}
.buttonConfirmNecessary {
  font-size: 1.2rem;
  background-color: rgb(201, 201, 201);
}
@media (hover: hover) and (pointer: fine) {
  .buttonConfirm:hover,
  .buttonConfirmNecessary:hover {
    background-color: var(--clr_teal);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
  }
}
.buttonSettings {
  background: none;
  cursor: pointer;
  border: none;
  text-decoration: underline;
  color: var(--font_color_light);
}
.deleteButton {
  margin-top: 1rem;
  padding: 0.2rem 1rem;
}
.footer {
  gap: 1rem;
}
.link:visited {
  color: var(--font_color_light);
}

/* TODO implement later */
/* .flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
} */
