.linkBox {
  padding-top: 0.5rem;
  text-decoration: none;
  font-size: 2.2rem;
  text-align: center;
  color: var(--font_color_light);
  font-family: 'Indie Flower', Sans-Serif;
  font-weight: 900;
}
.heading {
  font-size: 1.8rem;
  font-weight: var(--font-weightSidebarHeading);
  font-family: var(--font-familySidebar), sans-serif;
  text-decoration: underline;
}
@media screen and (max-width: 1150px) {
  .linkBoxImg {
    max-width: 50rem;
    align-self: center;
  }
}
@media screen and (max-width: 500px) {
  .linkBoxImg {
    max-width: 100%;
  }
}
