.App {
  height: 100vh;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}
.contentLiItem {
  list-style: none;
  margin-bottom: 2rem;
}

.divScrollButton {
  position: absolute;
  opacity: 1;
  transition: all 300ms;
  z-index: 99999;
  bottom: 2rem;
  right: 2.4rem;
  font-size: 3rem;
  padding: 0.6rem;
  border-radius: var(--border-radius);
  background-color: rgba(172, 172, 172, 0.562);
  border: none;
}
.divScrollButton--hide {
  opacity: 0;
  pointer-events: none;
}
.modalOn {
  /* position: fixed; */
  top: 0;
  overflow: hidden;
}
@media screen and (max-width: 1150px) {
  .pageContent {
    flex-direction: column;
    align-items: center;
    padding: 1.4rem;
  }
  .divScrollButton {
    bottom: 4rem;
  }
}
@media screen and (max-width: 500px) {
  .divScrollButton {
    bottom: 6rem;
  }
}

@media screen and (max-width: 500px) and (-webkit-min-device-pixel-ratio: 0) {
  .safari {
    bottom: 8rem;
  }
}
