.resultHeaderBox {
  display: flex;
  align-items: center;
  width: 100%;
  width: 93vw;
  margin-bottom: 1rem;
  border-bottom: var(--clr_borderBlog) double 6px;
}
.resultHeaderText {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 1rem;
  font-size: 2rem;
  width: min-content;
  padding: 0.5rem;
}
.text {
  text-wrap: nowrap;
}
