.paypal {
  border: rgb(192, 192, 192) solid 5px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: var(--border-radius);
  padding: 1rem;
}
.paypalButtonDiv {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
}
.paypalButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem 1rem 1rem;
  max-width: 15rem;
  border-radius: var(--border-radius);
  background-color: rgb(223, 223, 223);
  cursor: pointer;
  transition: all 200ms ease-in-out;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  -webkit-tab-highlight-color: transparent;
}
.paypalButton:hover {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.3);
  scale: 1.04;
}

.paypalButton:active {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
  scale: 1.01;
}

.img {
  max-width: 100%;
}
@media screen and (max-width: 1150px) {
  .paypal {
    flex-direction: row;
  }
}
@media screen and (max-width: 600px) {
  .paypal {
    flex-direction: column;
  }
}
