.nav {
  height: 100%;
}
.navbar {
  display: none;
  padding: 0 1.6rem;
  align-items: center;
  height: 100%;
  gap: 1rem;
  font-size: 1.8rem;
  font-weight: 400;
  z-index: 999;
  transition: all 200ms ease-in-out;
  border-radius: var(--border-radius);
}
.hideNavbar {
  opacity: 0;
  margin-top: -3rem;
  margin-right: 0rem;
  pointer-events: none;
}
.divHamburgerMenu {
  display: none;
  justify-content: end;
}

.divHamburger {
  width: min-content;
  height: 5rem;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}
.navbar a {
  text-decoration: none;
  color: var(--font_color_light);
}
.modal {
  position: absolute;
  pointer-events: all;
  z-index: 99;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
}
.modalOff {
  pointer-events: none;
  display: none;
}
.modalMobile {
  backdrop-filter: blur(5px);
}
.divNavElement {
  white-space: nowrap;
  position: relative;
  list-style: none;
  padding: 1rem 0;
  z-index: 999;
}
/* BUG */
/* @media (hover: hover) and (pointer: fine) {
  .navElement:hover {
    cursor: pointer;
  }
} */
.textCrossedOut {
  text-decoration: line-through;
}

.main::before {
  content: '';
  display: block;
  position: absolute;
  top: 0.5rem;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: rgba(242, 190, 35, 0.8);
  transform-origin: right top;
  transform: scale(0, 1);
  transition: color 100ms, transform 200ms ease-out;
}
/* BUG temporary removed FIXME Why hover hover and why its broken ?*/
/* @media (hover: hover) and (pointer: fine) { */
.main:hover::before,
.main:focus::before {
  transform-origin: left top;
  transform: scale(1, 1);
}
/* } */
.dropdown {
  position: absolute;
  top: 2.6rem;
  transition: all 150ms ease-in-out;
  background-color: rgb(221, 221, 221);
  padding: 0.2rem;
  opacity: 0;
  pointer-events: none;
  border-radius: var(--border-radius);
  transform: translateY(-1rem);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}
.dropdownShow {
  transform: translateY(1rem);
  opacity: 1;
  pointer-events: all;
}

@media screen and (max-width: 1150px) {
  .divHamburgerMenu {
    display: flex;
  }
}

@media screen and (min-width: 1150px) {
  .navbar {
    display: flex;
  }
}
