.blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  align-self: center;
}
.blogHeaderSearch {
  display: flex;
  gap: 1rem;
}

.blogTag {
  width: 120%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  overflow-wrap: break-word;
}

.tags {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--clr_tagBackground);
  border: var(--clr_borderBlog) solid 2px;
  border-radius: 0.8rem;
  width: min-content;
  cursor: pointer;
  white-space: nowrap;
}

.tags:hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.tagsIcon {
  display: flex;
  align-items: center;
  font-size: 2rem;
  padding: 0 0.8rem;
}

.tagChecked {
  outline: var(--clr_yellow) solid 3px;
  outline-offset: -2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}
.blogSearch {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  border: #cac7bc solid 2px;
  background-color: var(--clr_tagBackground);
  gap: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blogSearch input {
  border: var(--clr_borderBlog) solid 2px;
  padding: 0.2rem 0.4rem;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  background-color: var(--clr_tagBackground);
}
.searchSubmit {
  cursor: pointer;
}
.resultHeader {
  font-size: 2rem;
  width: 100%;
  width: 93vw;
  padding: 0 0.5rem 0.5rem 0.5rem;
  margin-bottom: 1rem;
  border-bottom: var(--clr_borderBlog) double 6px;
}
.blogGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, max-content));
  gap: 1rem;
  border-radius: 0.8rem;
  min-height: 28rem;
  justify-content: center;
  margin-bottom: 2rem;
}
.blogBox {
  width: 93vw;
}
/* 
not needed
@media screen and (max-width: 1150px) {
  .blogBox {
    width: 100%;
  }
} */
