.card {
  position: relative;
  border: var(--clr_borderBlog) solid 2px;
  border-radius: var(--border-radius);
  max-height: 50rem;
  height: min-content;
  overflow: hidden;
  max-width: 40rem;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: var(--clr_background_light);
}
.link {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: +1;
}

.fadeBox {
  padding: 1.4rem 1rem 1rem 1rem;
}
.card:hover {
  transition: 0.2s ease-in-out;
  scale: 1.03;
  z-index: 99;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
}
.card:active {
  transition: 0.15s ease-in-out;
  scale: 1.01;
  z-index: 99;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
.cardBox {
  position: relative;
}
.cardBox img {
  width: 80%;
}
.card .cardBox h1,
.card .cardBox h2,
.card .cardBox h3,
.card .cardBox h4,
.card .cardBox h5,
.card .cardBox p {
  margin-left: 1rem;
  margin-right: 1rem;
}
/* .card .cardBox h2 {
  margin-top: 1.6rem;
} */
.card p {
  text-overflow: ellipsis;
}
.card img {
  border-radius: 1.4rem;
  /* border-radius: 1.4rem 1.4rem 0 0; */
}
.card ul {
  /* transform: translateX(1.2rem) !important; */
  max-width: 90% !important;
}
.cardBox a {
  pointer-events: none;
}
.bottomFade {
  position: absolute;
  left: 0;
  bottom: -1.6rem;
  width: 100%;
  height: 7rem;
  background-color: var(--clr_background_light);
  -webkit-mask-image: linear-gradient(
    to top,
    var(--clr_background_light) 50%,
    transparent 100%
  );
  mask-image: linear-gradient(
    to top,
    var(--clr_background_light) 50%,
    transparent 100%
  );
}
