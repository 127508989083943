.chartBox {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
}
.chart {
  overflow-y: hidden;
  display: inline-grid;
  border-top: var(--clr_borderBlog) solid 1px;
  border-left: var(--clr_borderBlog) solid 1px;
  border-bottom: var(--clr_borderBlog) solid 2px;
  border-right: var(--clr_borderBlog) solid 2px;
}
.item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.2rem 0.5rem;
  border-top: var(--clr_borderBlog) solid 1px;
  border-left: var(--clr_borderBlog) solid 1px;
}
.itemHead {
  background-color: var(--clr_tagBackground);
  border-bottom: var(--clr_borderBlog) solid 1px;
  /* white-space: nowrap; */
  font-weight: bold;
  padding: 0.2rem 1rem;
}

.itemFooter {
  border-top: var(--clr_borderBlog) double 2px;
  background-color: var(--clr_tagBackground);
  font-weight: bold;
}
