.content {
  display: flex;
  justify-content: start;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100rem;
  width: 70%;
  max-width: 80rem;
  position: relative;
}
.content img {
  max-width: 100%;
}
.content ol {
  transform: translateX(3rem);
  max-width: 95%;
}
.content ul {
  transform: translateX(3rem);
  max-width: 95%;
}
.content ul li,
.content ol li {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.redingTimeBox {
  display: flex;
  justify-content: end;
  padding: 0 1rem;
  gap: 2rem;
}
.redingTimeBox p {
  margin: 0;
}
.contentImgRadius img {
  border-radius: 0;
}

@media screen and (max-width: 1150px) {
  .content {
    width: 100%;
    max-width: 120rem;
    font-size: 1.6rem;
  }
}
