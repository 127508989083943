.header {
  position: relative;
  left: 0;
  display: flex;
  width: 100vw;
  height: 5rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 99;
  background: var(--linear_gradientOT);
}

.divLogo {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50rem;
  transform: translateX(2rem) translateY(0rem);
  transition: all 200ms ease-in-out;
  filter: drop-shadow(3px 4px 3px rgba(0, 0, 0, 0.3));
}
@media (hover: hover) and (pointer: fine) {
  .divLogo:hover {
    scale: 1.05;
  }
}
.divLogo:active {
  scale: 1.01;
  filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.3));
}
.logoT {
  max-width: 5rem;
}
.logoImgA {
  height: 100%;
  width: 15rem;
  gap: 0.3rem;
  padding: 1.2rem 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoImgA img {
  width: 15rem;
}
.divNav {
  position: absolute;
  display: flex;
  width: min-content;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  justify-content: center;
}
.divIcon {
  position: absolute;
  height: 100%;
  right: 3rem;
  display: flex;
  gap: 1rem;
  z-index: -1;
}
@media screen and (max-width: 1150px) {
  .divIcon {
    right: 50%;
    transform: translateX(50%);
    margin-left: min(10rem);
  }
  .divNav {
    justify-content: flex-end;
    /* z-index: 2; */
    padding: 0 1.6rem;
    left: 100%;
    transform: translateX(-100%);
  }
}
@media screen and (max-width: 500px) {
  .divIcon {
    display: none;
  }
}
