.highLightBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  margin-bottom: 1rem;
  width: 100%;
  /* padding: 0rem 1rem 1rem 1rem; */
}

.rounded {
  border-radius: var(--border-radius);
}
.multiply {
  mix-blend-mode: multiply;
}
