.leafletOuterBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.leafletBox {
  width: 800px;
  height: 600px;
  max-width: 86vw;
}
.checkboxDiv {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
}

.checkboxDiv label input {
  margin: 1rem;
}
