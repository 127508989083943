.marker img {
  color: yellow !important;
  border: red solid 2px;
  width: 10rem;
}
.buttonBox {
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding: 0.3rem;
  /* border: rgb(189, 189, 189) solid 2px; */
  border-radius: 0.5rem;
}
.starChecked {
  color: var(--clr_yellow);
}
p {
  font-size: 1.4rem;
}

.icon {
  /* scale: 2; */
  width: 4rem;
}
.icon:hover {
  scale: 1.1;
  /* filter: drop-shadow(1px 1px 8px #00000073); */
  filter: drop-shadow(2px 2px 5px #00000091);
}
