.imgBoxComponent {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-bottom: 1rem;
  background-color: rgb(235, 235, 235);
  padding: 0 1rem;
}

.imgBox {
  position: relative;
  display: flex;
  align-self: center;
}
.textBoxes {
  padding: 0 0.8rem;
}
.textBox2 {
  margin-bottom: 1rem;
}
.currentImgBox {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
}
.currentImgBoxPortrait {
  aspect-ratio: 3/4;
}
.img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}
.flexBoxBtn {
  all: unset;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  font-size: 5rem;
  border-radius: 20rem;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.473);
  padding: 2rem;
  -webkit-tap-highlight-color: transparent;
}
.flexBoxBtnRe {
  transform: translateY(-50%) translateX(1rem);
  right: 0;
}

.flexBoxBtn:active {
  scale: 1;
  color: rgba(255, 255, 255, 0.911);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.4));
}
@media (hover: hover) and (pointer: fine) {
  .flexBoxBtn:hover {
    scale: 1.035;
    color: rgba(255, 255, 255, 0.911);
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.4));
  }
}
@media screen and (max-width: 1150px) {
  .flexBoxBtn:hover {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
    color: rgba(255, 255, 255, 0.473);
  }
  .flexBoxBtn {
    scale: 1;
    font-size: 4rem;
    transform: translateY(-50%) translateX(-2rem);
  }
  .flexBoxBtnRe {
    scale: 1;
    transform: translateY(-50%) translateX(2rem);
    font-size: 4rem;
  }
}
.rounded {
  border-radius: var(--border-radius);
}

.imgThumbnailBox {
  display: flex;
  gap: 1rem;
  width: 100%;
  padding: 1rem 1rem;
  max-height: 15rem;
}
.imgThumbnailBoxScroll {
  overflow-x: scroll;
}
.imgThumbnailBoxCentered {
  overflow-x: hidden;
  justify-content: center;
}
.thumbnailItem {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 12rem;
  aspect-ratio: 3/2;
  object-fit: cover;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.thumbnailItemPortrait {
  aspect-ratio: 4/5;
}
.thumbnailItem:hover {
  scale: 1.05;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
}
.thumbnailItem:active {
  scale: 1.01;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
}
.thumbnailItemActive {
  border: var(--clr_yellow) solid 4px;
  scale: 1.05;
  /* outline: var(--clr_yellow) solid 4px; */
}
