:root {
  --clr_background_light: #f8f8f8;
  /* --clr_background_light: #f1f1f1; */
  --clr_background_dark: #282c34;

  /* main color palette */
  --clr_orange: #f29727;
  --clr_teal: #22a699;
  --clr_yellow: #f2be23;
  --clr_red: #f24c3d;
  --clr_grey: #aaa9a9;
  --clr_LightGrey: #d1d1d1;
  --clr_SuperLightGrey: #e0e0e0;

  --clr_logoBackground: #fff3cd;
  --clr_tagBackground: #f1f1e2;
  --clr_tagBackgroundHover: #eec854;

  /* accent colors */
  --clr_orangeHeader: rgba(242, 190, 35, 0.3);
  --clr_tealHeader: rgba(34, 166, 153, 0.4);
  --clr_orangeSlider: rgba(242, 190, 35, 0.5);
  --clr_tealH2: rgba(34, 166, 153, 0.65);
  --clr_tealFooter: rgba(34, 166, 153, 0.8);

  --clr_borderBlog: #cac7bc;

  --linear_gradientOT: linear-gradient(
      275deg,
      rgba(34, 166, 153, 0.9),
      rgba(255, 0, 0, 0) 60%
    ),
    linear-gradient(135deg, rgba(242, 190, 35, 1), rgba(0, 255, 0, 0) 60%);

  --linear_gradientTO: linear-gradient(
      135deg,
      rgba(34, 166, 153, 1),
      rgba(255, 0, 0, 0) 60%
    ),
    linear-gradient(275deg, rgba(242, 190, 35, 1), rgba(0, 255, 0, 0) 60%);

  --linear_gradientSidebar: linear-gradient(
    160deg,
    rgba(242, 190, 35, 0.8),
    rgba(242, 190, 35, 0.3) 100%
  );
  --linear_gradientSidebar2: linear-gradient(
    135deg,
    rgba(242, 190, 35, 0.5),
    rgba(34, 166, 153, 1) 100%
  );
  --linear_gradientMenu1: linear-gradient(
    270deg,
    rgba(34, 166, 153, 1),
    rgb(40, 196, 180) 100%
  );

  /* --linear_gradientLogo: linear-gradient(
    160deg,
    rgba(242, 190, 35, 0.75),
    rgb(236, 219, 165) 100%
  );
  --linear_gradientLogo2: linear-gradient(
      275deg,
      rgba(240, 198, 74, 1),
      rgba(255, 0, 0, 0) 60%
    ),
    linear-gradient(135deg, rgb(238, 223, 177, 1), rgba(0, 255, 0, 0) 80%),
    linear-gradient(45deg, rgb(72, 168, 159), rgba(0, 255, 0, 0) 60%); */

  --border-radius: 1.6rem;

  --ff_main: 'Roboto', sans-serif;
  --ff_indie: 'Indie Flower', Sans-Serif;

  --font-weightSidebarHeading: 500;
  --font-familySidebar: 'roboto';

  --font_size_medium: 1.8rem;
  --font_color_light: rgb(88, 88, 88);
  --font_color_footer: rgb(63, 63, 63);
  /* --font_color_light: rgb(109, 109, 109); */
  --font_color_dark: #f1f1f1;
}
